

























































































































import { Component, Vue } from 'vue-property-decorator';
import DefineInvestmentTermViewModel from '@/vue-app/view-models/allianz-dashboard/access-more-strategies/define-investment-term-view-model';

@Component({
  name: 'DefineInvestmentTerm',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
    AccessMoreStrategiesSeeMoreModerateDialog: () => import('@/vue-app/components/allianz-dashboard/access-more-strategies/AccessMoreStrategiesSeeMoreModerateDialog.vue'),
    StrategyPesosInformationDialog: () => import('@/vue-app/components/goals-dashboard/create-goal/bucket/StrategyPesosInformationDialog.vue'),
  },
})
export default class DefineInvestmentTerm extends Vue {
  view_model = Vue.observable(
    new DefineInvestmentTermViewModel(this),
  );

  created() {
    this.view_model.initialize();
  }
}
